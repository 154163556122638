// @flow
import React from 'react';
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import styles from './FooterMobile.module.scss';
import arrowDownIcon from '../../images/icon-link-box-arrow-down-white.svg';
import { trackAppDownload } from '../../utils';

const FooterMobile = () => {
  return (
    <div className={styles.footerWrapper}>
      <footer>
        <Link to="/">
          <img
            className={styles.logo}
            src={require('../../images/logo-white-2.svg')}
            alt="Paidy"
            height="40"
            width="142"
            loading="lazy"
          />
        </Link>
        <nav className={styles.app}>
          <a
            title="Paidy | App Store"
            className={styles.badge}
            href="https://paidy.onelink.me/eV85/PCfooter"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => trackAppDownload('App Store', 'footer')}
          >
            <img
              src={require('../../images/badge-app-store.svg')}
              alt="Paidy | App Store"
              width="140"
              height="42"
              loading="lazy"
            />
          </a>
          <a
            title="Paidy | Google Play"
            className={styles.badge}
            href="https://paidy.onelink.me/KTFT/PCfooter"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => trackAppDownload('Google Play', 'footer')}
          >
            <img
              src={require('../../images/badge-google-play.svg')}
              alt="Paidy | Google Play"
              width="140"
              height="42"
              loading="lazy"
            />
          </a>
        </nav>
        <Accordion className={styles.accordion}>
          <AccordionSummary
            expandIcon={
              <img
                src={arrowDownIcon}
                alt="toggle-icon"
                width="24"
                height="24"
              />
            }
            aria-controls="あと払いペイディ-content"
            id="あと払いペイディ-header"
          >
            <div className={styles.title}>あと払いペイディ</div>
          </AccordionSummary>
          <AccordionDetails>
            <nav>
              <Link to="/payments/">使い方</Link>
              <Link to="/shop/">使えるお店</Link>
              <Link to="/npay/">3・6・12回あと払い</Link>
              <Link to="/paidycard/">ペイディカード</Link>
              <Link to="/apple/">ペイディあと払いプランApple専用</Link>
            </nav>
          </AccordionDetails>
        </Accordion>

        <Accordion className={styles.accordion}>
          <AccordionSummary
            expandIcon={
              <img
                src={arrowDownIcon}
                alt="toggle-icon"
                width="24"
                height="24"
              />
            }
            aria-controls="お役立ち情報-content"
            id="お役立ち情報-header"
          >
            <div className={styles.title}>お役立ち情報</div>
          </AccordionSummary>
          <AccordionDetails>
            <nav>
              <Link to="/guide/">ご利用ガイド</Link>
              <Link to="/campaign/">キャンペーン</Link>
              <Link to="/column/">お知らせ</Link>
              <Link to="/security/">安全への取り組み</Link>
              <OutboundLink
                title="Paidy | お問い合わせ"
                href="https://cs-support.paidy.com/support/home"
                target="_blank"
                rel="noopener noreferrer"
              >
                よくあるご質問
              </OutboundLink>
            </nav>
          </AccordionDetails>
        </Accordion>

        <Accordion className={styles.accordion}>
          <AccordionSummary
            expandIcon={
              <img
                src={arrowDownIcon}
                alt="toggle-icon"
                width="24"
                height="24"
              />
            }
            aria-controls="企業情報-content"
            id="企業情報-header"
          >
            <div className={styles.title}>企業情報</div>
          </AccordionSummary>
          <AccordionDetails>
            <nav>
              <Link to="https://corp.paidy.com/about/">会社プロフィール</Link>
              <Link to="https://corp.paidy.com/news/">プレスリリース</Link>
              <Link to="https://corp.paidy.com/recruit/">採用情報</Link>
              <Link to="https://corp.paidy.com/hr-blog/">HRブログ</Link>
              <OutboundLink
                title="Paidy | 利用規約"
                href="https://terms.paidy.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                利用規約
              </OutboundLink>
            </nav>
          </AccordionDetails>
        </Accordion>
        <Accordion className={styles.accordion}>
          <AccordionSummary
            expandIcon={
              <img
                src={arrowDownIcon}
                alt="toggle-icon"
                width="24"
                height="24"
              />
            }
            aria-controls="導入について-content"
            id="導入について-header"
          >
            <div className={styles.title}>導入について</div>
          </AccordionSummary>
          <AccordionDetails>
            <nav>
              <Link to="/merchant/application/">導入の流れ</Link>
              <Link to="/merchant/#case_study/">導入事例</Link>
              <Link to="/merchant/application/#documents">導入申込み</Link>
              <OutboundLink
                className={styles.badge}
                href="https://paidy.com/docs/jp/"
                target="_blank"
                rel="noopener noreferrer"
              >
                技術ドキュメント
              </OutboundLink>
            </nav>
          </AccordionDetails>
        </Accordion>
        <Accordion className={styles.accordion}>
          <AccordionSummary
            expandIcon={
              <img
                src={arrowDownIcon}
                alt="toggle-icon"
                width="24"
                height="24"
              />
            }
            aria-controls="法人のお客様-content"
            id="法人のお客様-header"
          >
            <div className={styles.title}>法人のお客様</div>
          </AccordionSummary>
          <AccordionDetails>
            <nav>
              <Link to="/merchant/">法人のお客様</Link>
              <OutboundLink
                className={styles.badge}
                href="https://merchant.paidy.com/login"
                target="_blank"
                rel="noopener noreferrer"
              >
                加盟店ログイン
              </OutboundLink>
              <Link to="/merchant/contact-us">法人/PRお問合せ</Link>
            </nav>
          </AccordionDetails>
        </Accordion>
        <div className={styles.misc}>
          <nav className={styles.sns}>
            <OutboundLink
              className={styles.badge}
              href="https://twitter.com/paidyjp"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require('../../images/icon-x-white.svg')}
                alt="Paidy | Twitter"
                width="40"
                height="40"
                loading="lazy"
              />
            </OutboundLink>
            <OutboundLink
              className={styles.badge}
              href="https://www.linkedin.com/company/paidy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require('../../images/icon-linkedin-2.svg')}
                alt="Paidy | Linkedin"
                width="40"
                height="40"
                loading="lazy"
              />
            </OutboundLink>
          </nav>
          <small>&copy; Paidy Inc.</small>
        </div>
      </footer>
    </div>
  );
};

FooterMobile.defaultProps = { isMerchant: false };

export default FooterMobile;
